<template>
  <q-intersection transition="slide-left">
    <div class="text-h6 text-center">
      {{ $t("forms.tips.we_want_to_know_more_about_you") }}
    </div>

    <q-form @submit="onSubmit()" greedy class="q-mt-md">
      <div
        class="text-weight-bold"
        v-html="$t('forms.tips.select_categories')"
      />
      <div
        class="text-grey text-italic"
        v-html="$t('forms.tips.select_categories_tip')"
      />
      <q-select
        filled
        multiple
        use-chips
        class="q-mt-sm"
        @filter="filterFn"
        input-debounce="0"
        @new-value="createValue"
        v-model="modelAddUnique"
        :options="partnerCategories"
        :rules="[
          (val) =>
            (val.length > 0 && val.length <= 5) ||
            $t('forms.validation.only_5'),
        ]"
        options-selected-class="bg-grey-8 text-white"
        lazy-rules="ondemand"
      />
      <div
        class="text-weight-bold"
        v-html="$t('forms.tips.whats_ur_main_social_network')"
      />
      <div class="text-grey text-italic" v-html="$t('forms.tips.case_site')" />
      <q-select
        filled
        class="q-mt-sm"
        :options="networks"
        v-model="socialNetwork"
        :label="$t('forms.tips.select_network')"
        :rules="[(val) => !!val || $t('forms.validation.required')]"
        lazy-rules="ondemand"
      />
      <q-input
        filled
        class="q-mt-sm"
        v-model="socialNetworkLink"
        :label="$t($t('forms.tips.network_link'))"
        :mask="getNetworksConfig(socialNetwork, 'mask')"
        :type="getNetworksConfig(socialNetwork, 'type')"
        :prefix="getNetworksConfig(socialNetwork, 'prefix')"
        :rules="[(val) => validateMainNetwork(val, socialNetwork)]"
        lazy-rules="ondemand"
      />

      <div class="q-mt-lg row">
        <div class="col-12">
          <q-btn
            outline
            type="submit"
            color="white"
            :label="$t('login.signUp.next')"
            class="width-100 bg-grey-10"
          />
        </div>
      </div>
    </q-form>
  </q-intersection>
</template>

<script>
import {
  verifyIfIsLink,
  getNetworksConfig,
  validateMainNetwork,
} from "./setup";
import { ref, watch } from "vue";

export default {
  name: "formStepFour",

  emits: ["finished", "return"],

  props: {
    categories: {
      require: true,
      type: Array,
    },
    networks: {
      require: true,
      type: Array,
    },
  },

  setup(props, root) {
    const socialNetwork = ref(null),
      socialNetworkLink = ref(""),
      modelAddUnique = ref([]);

    const partnerCategories = ref(
      props.categories.map((item) => item.description)
    );

    const filterFn = (val, update) => {
      update(() => {
        if (val === "") {
          return;
        } else {
          const needle = val.toLowerCase();
          modelAddUnique.value = partnerCategories.value.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        }
      });
    };

    const getIdsByCategoriesDesc = (categories) => {
      let ids = [];

      categories.forEach((category) => {
        const categoryFound = props.categories.find(
          (item) => item.description === category
        );
        if (categoryFound) ids.push(categoryFound.id);
      });

      return ids;
    };

    const onSubmit = () => {
      const data = getData("submit");

      root.emit("finished", {
        data,
        goTo: 5,
      });
    };

    const returnForm = () => {
      const data = getData("return");
      localStorage.setItem("signUpStepFour", JSON.stringify(data));

      root.emit("return");
    };

    const getData = (type) => ({
      main_network: {
        network: socialNetwork.value ? socialNetwork.value.value : "",
        content: socialNetworkLink.value,
      },
      categories:
        type == "submit"
          ? getIdsByCategoriesDesc(modelAddUnique.value)
          : modelAddUnique.value,
    });

    const data = localStorage.getItem("signUpStepFour");

    if (data) {
      const { main_network, categories } = JSON.parse(data);
      modelAddUnique.value = categories;
      socialNetwork.value = props.networks.find(
        (item) => item.value === main_network.network
      );
      socialNetworkLink.value = main_network.content;
    }

    watch(socialNetwork, () => (socialNetworkLink.value = ""));

    return {
      socialNetwork,
      modelAddUnique,
      socialNetworkLink,
      partnerCategories,
      onSubmit,
      filterFn,
      returnForm,
      verifyIfIsLink,
      getNetworksConfig,
      validateMainNetwork,
      getIdsByCategoriesDesc,
      createValue: () => {
        return;
      },
    };
  },
};
</script>
