<template>
  <div class="q-mt-lg text-weight-medium text-grey">
    <q-separator class="q-ma-md" inset="" size="3px" />
    <q-item :to="{ name: 'login' }">
      <p class="text-sign-up-footer row  text-center">
        {{ $t("login.signUp.u_have_account") }}
      </p>
      <span class="text-dark q-ml-sm">{{ $t("global.enter") }}</span>
    </q-item>
  </div>
  <q-dialog
    v-model="dialog"
    persistent
    :maximized="true"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-white text-dark o">
      <q-bar class="bg-dark">
        <q-space />
        <q-btn color="white" dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-black text-white">{{
            $t("global.close")
          }}</q-tooltip>
        </q-btn>
      </q-bar>
      <div class="row justify-center">
        <div class="terms-content scroll">
          <quill-editor
            :value="docContent.content"
            :disabled="state.disabled"
            :options="state.editorOption"
          />
        </div>
      </div>
      <q-card-actions align="center">
        <q-btn flat :label="$t('global.close')" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import quillEditor from "@/modules/main/components/quillEditor";
import { ref, reactive } from "vue";

export default {
  components: {
    quillEditor,
  },

  props: {
    docContent: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const state = reactive({
      editorOption: {
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: false,
        },
      },
      disabled: true,
    });

    return {
      state,
      dialog: ref(false),
    };
  },
};
</script>

<style lang="scss" scoped>
.text-sign-up-footer {
  a {
    text-decoration: none;
    color: #222222;
    font-weight: 600;
  }
}
.terms-content {
  max-height: 90vh;
  max-width: 900px;
}
</style>
