<template>
  <q-intersection transition="slide-left">
    <div class="q-gutter-sm q-mt-md">
      <div class="text-h6 text-center">
        {{ $t("forms.tips.how_do_you_identify") }}
      </div>
      <q-list>
        <q-item
          v-for="(type, i) in partnerTypes"
          :key="i"
          v-ripple
          class="q-mt-sm q-pt-md q-pb-md"
          style="border: 1px solid rgb(139, 139, 139); border-radius: 10px;"
        >
          <q-item-section avatar top>
            <q-radio
              left-label
              v-model="partner_type"
              :val="type.id"
              color="grey-8"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ type.singular_name }}</q-item-label>
            <q-item-label caption class="text-justify">
              {{ type.description }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="q-mt-lg row">
      <!--
      <div class="col-12 col-sm-6">
        <q-btn
          flat
          outline
          class="width-100"
          @click="returnForm"
          :label="$t('login.signUp.return')"
        />
      </div>
    -->
      <div class="col-12">
        <q-btn
          color="white"
          @click="onSubmit"
          class="width-100 bg-grey-10"
          :label="$t('login.signUp.next')"
        />
      </div>
    </div>
  </q-intersection>
</template>

<script>
import { notifyError } from "@/shared/helpers/notify";
import { ref } from "vue";

export default {
  name: "formStepThree",

  emits: ["finished", "return"],

  props: {
    partnerTypes: {
      require: true,
      type: Array,
    },
  },

  setup(props, root) {
    const partner_type = ref(0);

    const onSubmit = () => {
      if (partner_type.value === 0) {
        notifyError("Por favor, selecione uma opção");
        return;
      }

      const data = {
        partner_type_id: partner_type.value,
      };

      localStorage.setItem("signUpStepThree", JSON.stringify(data));

      root.emit("finished", {
        data,
        goTo: 4,
      });
    };

    const returnForm = () => root.emit("return");

    const data = localStorage.getItem("signUpStepThree");

    if (data) partner_type.value = JSON.parse(data).partner_type_id;

    return {
      partner_type,
      onSubmit,
      returnForm,
    };
  },
};
</script>
