<template>
  <div class="text-center">
    <q-item-label class="completed-title text-grey-9">{{
      layout.title
    }}</q-item-label>
    <q-item-label class="q-mb-md completed-subtitle text-grey-7">{{
      layout.subtitle
    }}</q-item-label>
    <p
      class="text-justify text-weight-medium text-grey-7 completed-dialog-body-text"
      v-html="layout.message"
    />
  </div>
  <q-separator class="q-ma-md" inset="" size="3px" />
  <p class="text-sign-up-footer text-center">
    {{ layout.confirm }}
    <q-item :to="{ name: 'login' }">
      <q-item-label class="text-blue-11">
        {{ $t("global.enter") }}
      </q-item-label>
    </q-item>
  </p>
</template>

<script>
export default {
  name: "CompletedRegister",

  props: {
    layout: {
      require: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.completed {
  &-title {
    font-size: 25px;
    font-weight: 500;
  }
  &-subtitle {
    margin-top: 2em;
    font-size: 1.3em;
    font-weight: 400;
  }
  &-dialog-body-text {
    margin-top: 2em;
  }
}
.mobile {
  .completed {
    &-title {
      font-size: 1.4em;
    }
    &-subtitle {
      font-size: 1.5em;
    }
    &-dialog-body-text {
      font-size: 1.3em;
    }
  }
}
</style>
