<template>
  <q-dialog
    v-model="confirm"
    persistent
    :position="$q.platform.is.desktop ? 'standard' : 'bottom'"
  >
    <q-card class="bg-grey-11 default-rounded">
      <q-card-section class="row items-center justify-center">
        <q-avatar
          class="q-mb-lg"
          size="5em"
          icon="campaign"
          color="light-blue-10"
          text-color="white"
        />
        <span
          class="q-ml-sm text-weight-medium text-light-blue-10 text-center dialog-title"
          v-html="content.title"
        />
      </q-card-section>
      <q-card-section style="max-height: 50vh" class="scroll">
        <p
          class="text-justify text-weight-medium text-grey-7 dialog-body-text"
          v-html="content.message"
        />
      </q-card-section>
      <q-card-section>
        <q-item tag="label">
          <q-item-section avatar top>
            <q-checkbox keep-color v-model="agreeTerms" color="grey-7" />
          </q-item-section>
          <q-item-section>
            <q-item-label v-html="content.confirm" />
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          :disable="!agreeTerms"
          :label="$t('global.continue')"
          color="text-light-blue-10"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const agreeTerms = ref(false);

    const confirm = ref(false);

    onMounted(() => {
      confirm.value = true;
    });

    return {
      confirm,
      agreeTerms,
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog-title {
  font-size: 1.2em;
}
.dialog-body-text {
  font-size: 1.1em;
}
</style>
