<template>
  <q-intersection transition="slide-left">
    <confirm-dialog
      :content="layout.warning_message"
      v-if="layout.warning_message"
    />
    <div class="text-center" v-if="stepper < 3">
      <q-item-label class="title text-grey-9">{{ layout.title }}</q-item-label>
      <q-item-label class="q-mb-md subtitle text-grey-9">{{
        layout.subtitle
      }}</q-item-label>
    </div>
    <!--
    <social-sign-up />
    -->
    <q-separator class="q-mt-sm q-mr-lg q-ml-lg q-mb-md" style="padding: 1px" />

    <SignupCounter :step="stepper" :totalSteps="5" />

    <component
      :is="getComponentName(stepper)"
      :networks="networks"
      :categories="categories"
      :type-person="person_types"
      :signup-cnaes="signup_cnaes"
      :partner-types="partner_types"
      :docContent="docContent"
      @changedPersonType="changedPersonType"
      @finished="onStepChange"
      @return="returnStep"
    />
    <footer-content v-if="docContent" :doc-content="docContent" />
  </q-intersection>
</template>

<script>
import ConfirmDialog from "@/modules/auth/pages/sign-up/v2/components/confirmDialog";
import FooterContent from "@/modules/auth/pages/sign-up/v2/components/footerContent";
import FormStepOne from "@/modules/auth/pages/sign-up/v2/components/formStepOne";
import FormStepTwo from "@/modules/auth/pages/sign-up/v2/components/formStepTwo";
import FormStepThree from "@/modules/auth/pages/sign-up/v2/components/formStepThree";
import FormStepFour from "@/modules/auth/pages/sign-up/v2/components/formStepFour";
import FormStepFive from "@/modules/auth/pages/sign-up/components/formStepThree";

import SignupCounter from "../../../components/SignupCounter.vue";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { generateSignUpPayload, getComponentName } from "./setup";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useRoute } from "vue-router";
import { ref, nextTick } from "vue";

const partnerNamespace = createNamespacedHelpers("partner"),
  { useActions, useGetters } = createNamespacedHelpers("auth");

export default {
  name: "Register",

  components: {
    FormStepOne,
    FormStepTwo,
    FormStepThree,
    FormStepFour,
    FormStepFive,
    FooterContent,
    ConfirmDialog,
    SignupCounter,
  },

  emits: ["submitted"],

  props: {
    layout: {
      require: true,
      type: Object,
    },
    typePerson: {
      require: true,
      type: Array,
    },
  },

  setup(props, root) {
    const stepper = ref(1),
      docContent = ref(null),
      route = useRoute();

    let formData = {};

    const { slugPartner } = partnerNamespace.useGetters({
        slugPartner: "getPartnerSlug",
      }),
      {
        slug_readonly,
        person_types,
        signup_cnaes,
        partner_types,
        networks,
        categories,
      } = useGetters({
        slug_readonly: "getSlugIsReadyOnly",
        person_types: "getRegisterPersonType",
        signup_cnaes: "getSignUpCnaes",
        partner_types: "getSignUpPartnerTypes",
        networks: "getSignUpSocialNetworks",
        categories: "getSignUpCategories",
      }),
      { fetchTerms } = useActions(["fetchTerms"]);

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const onStepChange = (values) => {
      formData = Object.assign(formData, values.data);

      if (values.goTo == "subscribe") submit();
      else stepper.value = values.goTo;
    };

    const changedPersonType = (type) => {
      docContent.value = null;
      nextTick(() => {
        fetchTerms({ type, companySlug: route.params.slug }).then(
          ({ data }) => {
            let mergedDocs = { ops: [] };

            mergedDocs.ops = data.reduce((acc, e) => {
              return acc.concat(e.doc.ops);
            }, []);

            docContent.value = {
              content: data.length ? mergedDocs : [],
              type: type,
            };
          }
        );
      });
    };

    const submit = async () => {
      // reCAPTCHA
      await recaptchaLoaded();
      let token = await executeRecaptcha("login"),
        socialNetworks = {
          social_networks: [formData.main_network].filter(
            (network) => network.content
          ), // Remove falsy values from the array
        };

      let data = generateSignUpPayload(
        { token },
        formData,
        socialNetworks,
        slugPartner.value,
        route.query.indicated_by
      );

      if (formData.email_validated_at)
        data.email_validated_at = formData.email_validated_at;

      if (formData.celular_validated_at)
        data.celular_validated_at = formData.celular_validated_at;

      root.emit("submitted", Object.assign(route.query, data));
    };

    const returnStep = () => (stepper.value = stepper.value - 1);

    changedPersonType("pf");

    return {
      stepper,
      networks,
      docContent,
      person_types,
      slug_readonly,
      signup_cnaes,
      partner_types,
      categories,
      returnStep,
      onStepChange,
      getComponentName,
      changedPersonType,
    };
  },
};
</script>

<style lang="scss">
.title {
  font-size: 25px;
  font-weight: 500;
}
.subtitle {
  font-size: 1em;
  font-weight: 400;
}
.mobile {
  .title {
    font-size: 1.2em;
  }
  .subtitle {
    font-size: 1em;
  }
}
</style>
