<template>
  <div class="q-pa-md default-rounded">
    <div class="row justify-center">
      <q-skeleton class="bg-grey-12" type="QRange" width="80%" />
    </div>
    <div class="row justify-center q-mt-lg">
      <q-skeleton class="bg-grey-12" type="QBtn" width="100%" />
      <q-skeleton class="bg-grey-12 q-mt-sm" type="QBtn" width="100%"/>
      <q-skeleton class="bg-grey-12 q-mt-sm" type="text" width="100%"/>
    </div>
    <div class="row">
      <div class="q-mt-lg full-width">
        <q-skeleton class="bg-grey-12" width="100%" height="400px" />
      </div>
    </div>
  </div>
</template>
<script>
import { onUnmounted, onMounted } from 'vue'
export default {
  setup(){
    onMounted( () => {
      document.body.classList.add('bg-skeleton')
    })
    onUnmounted( () => {
      document.body.classList.remove('bg-skeleton')
    })
  }
}
</script>
