<template>
  <div class="text-center">
    <q-item-label class="completed-title text-grey-9">{{ $t('login.signUp.unsuccessfully_title') }}</q-item-label>
    <p class="text-justify text-weight-medium text-grey-7 completed-dialog-body-text"
       v-html="$t('login.signUp.unsuccessfully_description')"/>
  </div>
  <q-separator class="q-ma-md" inset="" size="3px"/>
  <p class="text-sign-up-footer text-center">
    {{ $t('login.signUp.unsuccessfully_try_latter') }}
    <q-item :to="{ name: 'login' }">
      <q-item-label > {{ $t('login.signUp.back_to_start') }} </q-item-label>
    </q-item>
  </p>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
.completed{
  &-title{
    font-size: 25px;
    font-weight: 500;
  }
  &-subtitle{
    margin-top: 2em;
    font-size: 1.3em;
    font-weight: 400;
  }
  &-dialog-body-text{
    margin-top: 2em;
  }
}
.mobile{
  .completed{
    &-title{
      font-size: 1.4em;
    }
    &-subtitle{
      font-size: 1.5em;
    }
    &-dialog-body-text{
      font-size: 1.3em;
    }
  }
}
</style>