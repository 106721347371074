<template>
  <div v-if="show_form">
    <Form
      v-if="formInReset === false"
      class="column justify-between full-height q-mt-md"
      :initial-values="Object.assign({}, initialValues)"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div
        class="q-mb-md"
        style="margin-left: -5px"
        v-show="typePerson.length === 2"
      >
        <q-radio
          :key="key"
          color="dark"
          v-model="personType"
          :val="type.toLowerCase()"
          v-for="(type, key) in typePerson"
          @update:model-value="onChangedTypePerson"
          :label="$t(`login.signUp.${type == 'PF' ? 'physical' : 'juridic'}`)"
        />
      </div>
      <Field
        v-for="(structure, key) in structureForm"
        :key="key"
        :name="structure.name"
        v-slot="{ errorMessage, value, field }"
      >
        <q-input
          filled
          color="dark"
          bottom-slots
          class="q-mb-sm q-mt-sm"
          :debounce="1000"
          v-bind="field"
          :model-value="value"
          :type="structure.type"
          :mask="structure.mask"
          :error="!!errorMessage"
          :label="structure.label"
          :error-message="errorMessage"
          :counter="!!structure.maxlength"
          :maxlength="structure.maxlength"
          :stack-label="structure['stack-label']"
          @keyup="keepValue(structure.name, $event)"
          :prefix="structure.name.includes('instagram') ? '@' : undefined"
        >
          <template v-slot:append>
            <template v-if="structure.name.includes('celular')">
              <q-btn
                dense
                round
                icon="check"
                color="green"
                text-color="white"
                v-if="isSmsValidated == true && verifyHasSmsValidation()"
              />
            </template>
          </template>
        </q-input>
        <!-- Botão para abrir modal de validação de email -->
        <q-btn
          v-if="
            structure.name.includes('celular') &&
            errorMessage == undefined &&
            typeof value == 'string' &&
            value.length &&
            isSmsValidated == false &&
            verifyHasSmsValidation()
          "
          icon="call"
          color="white"
          class="q-mb-md bg-red"
          label="Clique para validar o celular"
          @click="openValidateSms(value)"
          :loading="in_request_sms_validate"
        />

        <q-item
          class="q-ma-none q-pa-none"
          v-if="canShowLoginRoute(field.name, errorMessage)"
          :to="{ name: 'login', query: { email: value } }"
        >
          <q-item-label class="q-mt-xs text-weight-bold text-blue-11">
            <small class="cursor-pointer">
              {{ $t("login.signUp.would_like_to_access") }}
            </small>
          </q-item-label>
        </q-item>
      </Field>

      <div class="q-mt-sm" v-if="personType == 'pj'">
        <div
          class="text-weight-bold"
          v-html="'Selecione abaixo todos os CNAES da sua empresa'"
        />
        <div class="text-red text-italic" v-html="$t('forms.tips.cnaes_tip')" />
        <div>
          <div class="q-mt-sm q-mb-sm text-italic">
            Válidos para parceiro MEI
          </div>
          <q-option-group
            color="grey-8"
            type="checkbox"
            style="font-size: 9pt"
            v-model="cnae_mei"
            :options="
              signupCnaes.mei.map((e) => ({
                label: e.description,
                value: e.external_id,
              }))
            "
            @update:model-value="clearCnae('mei')"
          />
        </div>
        <div>
          <div class="q-mt-sm q-mb-sm text-italic">
            Válidos para parceiro ME
          </div>
          <q-option-group
            color="grey-8"
            type="checkbox"
            style="font-size: 9pt"
            v-model="cnae_me"
            :options="
              signupCnaes.me.map((e) => ({
                label: e.description,
                value: e.external_id,
              }))
            "
            @update:model-value="clearCnae('me')"
          />
        </div>
      </div>
      <q-btn
        outline
        color="white"
        type="submit"
        :loading="in_request"
        :disable="in_request || !isSmsValidated"
        :label="$t('login.signUp.next')"
        class="width-100 bg-grey-10 q-mt-lg q-pa-sm q-pr-lg q-pl-lg"
      />
    </Form>
  </div>

  <ValidateEmailExt
    v-model="validateSms"
    :email="smsField"
    :hash="smsHash"
    typeValidation="celular"
    @resend="openValidateSms('', true)"
    @verify="verified()"
  />
</template>

<script>
import {
  labels,
  build,
  getStructure,
  getMappedFields,
} from "@/shared/form-presets";
import ValidateEmailExt from "@/modules/main/pages/account/children/verification-components/ValidateEmailExt.vue";
import { formatTimestamp } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { signUpStepOneConfig, getInitialValues } from "./setup";
import { ref, nextTick, onMounted /* watch*/ } from "vue";
import { notifyError } from "@/shared/helpers/notify";
import { useRoute, useRouter } from "vue-router";
import { baseURL } from "@/shared/http/api";
import { Field, Form } from "vee-validate";
import axios from "axios";

const authNamespace = createNamespacedHelpers("auth");
const accountNamespace = createNamespacedHelpers("account");

export default {
  name: "formStepOne",

  components: {
    Field,
    Form,
    ValidateEmailExt,
  },

  props: {
    typePerson: {
      type: Array,
      required: true,
    },
    signupCnaes: {
      type: Object,
      required: true,
    },
  },

  emits: ["finished", "changedPersonType"],

  setup(props, { emit }) {
    const formInReset = ref(false),
      router = useRouter(),
      route = useRoute(),
      in_request = ref(false),
      show_form = ref(false),
      schema = ref(null),
      cnae_mei = ref([]),
      cnae_me = ref([]);

    const smsHash = ref(""),
      smsField = ref(""),
      validateSms = ref(false),
      isSmsValidated = ref(false),
      in_request_sms_validate = ref(false);

    const { challengeCodes } = authNamespace.useGetters({
        challengeCodes: "getSignUpSkeletonChallengeCodes",
      }),
      { fetchSendChallengeExt, fetchResendChallengeExt } =
        accountNamespace.useActions([
          "fetchSendChallengeExt",
          "fetchResendChallengeExt",
        ]);

    /**
     * Quando a empresa não tem regra de validação de email, liberar o cadastro
     */
    const verifyHasSmsValidation = () => {
      const existSmsLink = challengeCodes.value.find((e) => e.channel == "sms");

      if (existSmsLink) return existSmsLink;
      else {
        isSmsValidated.value = true;
        return false;
      }
    };

    const localInitialValues = localStorage.getItem("signUpStepOne");

    const structureForm = ref(null),
      isSocialNetworkRequired = ref("0"),
      personType = ref(props.typePerson[0].toLowerCase()),
      initialValues = ref(
        getInitialValues(
          route.query.name,
          localInitialValues ? JSON.parse(localInitialValues) : null
        )
      );

    const buildFormSetup = (type) => {
      const fieldsDefinitions = signUpStepOneConfig(
        type,
        isSocialNetworkRequired.value
      );

      structureForm.value = getStructure(fieldsDefinitions);

      if (type === "pj")
        structureForm.value.name_default_contact.label =
          labels.name_default_contact;
      else structureForm.value.complete_name.label = labels.complete_name;

      schema.value = build(getMappedFields(fieldsDefinitions));
      nextTick(() => (formInReset.value = false));
    };

    const onChangedTypePerson = (newValue) => {
      formInReset.value = true;

      localStorage.removeItem("signUpStepOne");

      formInReset.value = false;

      emit("changedPersonType", newValue);
      nextTick(() => buildFormSetup(newValue));
    };

    const keepValue = (name, event) => {
      const newInitialValues = Object.assign({}, initialValues.value);

      newInitialValues[name] = event.target.value;
      initialValues.value = newInitialValues;
    };

    const verifySocialLogin = () => {
      axios
        .get(
          `${baseURL}/api/v1/parameters/p/${route.params.slug}/is_social_network_required`
        )
        .then(({ data }) => {
          show_form.value = true;
          isSocialNetworkRequired.value = data.value;
          buildFormSetup(personType.value);
        })
        .catch(() => router.push({ name: "login" }));
    };

    const onSubmit = (values) => {
      if (isSocialNetworkRequired.value == 1) {
        values.instagram = values.instagram_req;
        delete values.instagram_req;
      }

      if (personType.value == "pj") {
        if (cnae_mei.value.length == 0 && cnae_me.value.length == 0) {
          notifyError("Selecione pelo menos um CNAE");
          return;
        }
        values.pj_cnaes = cnae_mei.value.length
          ? cnae_mei.value
          : cnae_me.value;
      }

      in_request.value = true;

      let data = {
        ...values,
        type_person: personType.value,
        facebook_id: route.query.id || null,
        celular_validated_at: verifyHasSmsValidation()
          ? formatTimestamp(new Date())
          : null,
      };

      localStorage.setItem("signUpStepOne", JSON.stringify(data));

      emit("finished", { data, goTo: 2 });
    };

    /**
     * Verifica se o usuário já validou o celular
     */
    const openValidateSms = (value, resent = false) => {
      smsHash.value = verifyHasSmsValidation().hash;

      in_request_sms_validate.value = true;
      smsField.value = smsField.value && resent ? smsField.value : value;

      let payload = {
        value: normalizedPhone(value),
        type: "sms",
        hash: smsHash.value,
      };

      if (resent) {
        fetchResendChallengeExt(payload).finally(
          () => (in_request_sms_validate.value = false)
        );
      } else {
        fetchSendChallengeExt(payload)
          .then(() => (validateSms.value = true))
          .finally(() => (in_request_sms_validate.value = false));
      }
    };

    const normalizedPhone = (phone) => phone.replace(/[^0-9]/g, "");

    onMounted(() => {
      verifySocialLogin();
      const stepOne = localStorage.getItem("signUpStepOne");

      if (stepOne) {
        const { type_person, pj_cnaes } = JSON.parse(stepOne);
        personType.value = type_person;

        if (type_person === "pj") {
          props.signupCnaes.mei.forEach((cnae) => {
            if (pj_cnaes.includes(cnae.external_id))
              cnae_mei.value.push(cnae.external_id);
          });

          props.signupCnaes.me.forEach((cnae) => {
            if (pj_cnaes.includes(cnae.external_id))
              cnae_me.value.push(cnae.external_id);
          });
        }
      }
    });

    return {
      cnae_mei,
      cnae_me,
      schema,
      show_form,
      in_request,
      personType,
      formInReset,
      initialValues,
      structureForm,

      smsHash,
      smsField,
      validateSms,
      isSmsValidated,
      in_request_sms_validate,

      onSubmit,
      keepValue,
      onChangedTypePerson,
      verifyHasSmsValidation,
      canShowLoginRoute(fieldName, msgError) {
        return fieldName === "email" && msgError && msgError.includes("base");
      },
      clearCnae: (type) => {
        if (type === "mei") cnae_me.value = [];
        else cnae_mei.value = [];
      },
      openValidateSms,
      verified: () => {
        validateSms.value = false;
        isSmsValidated.value = true;
        localStorage.setItem("validatedCelular", true);
      },
    };
  },
};
</script>
